<template>
  <div class="col-container">
    <div class="col-1">
      <Nav />
      <div class="panel">
        <RouterLink class="panel-header" to="/prerel">Thông cáo báo chí</RouterLink>
        <div class="panel-body panel-body__no-padding">
          <div class="news-list news-list__vertical">
            <div v-for="item in pressRelease" :key="item.id" class="news-list-item">
              <a :href="'https://www.sbvgov.com/audio/' + item.download " class="news-list-item--title">{{ item.title }}</a>
              <div class="news-list-item--extra">
                <span>({{ item.date }})</span>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer panel-footer__align-right">
          <RouterLink to="/prerel">More</RouterLink>
        </div>
      </div>
      <div class="panel">
        <a class="panel-header">Tài liệu pháp lý</a>
        <div class="panel-body panel-body__no-padding">
          <div class="news-list news-list__vertical">
            <div v-for="item in pressRelease2" :key="item.id" class="news-list-item">
              <a :href="'https://www.sbvgov.com/audio/' + item.download " class="news-list-item--title">{{ item.title }}</a>
              <div class="news-list-item--extra">
                <span>({{ item.date }})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="panel">
        <div class="panel-header">Tin nổi bật</div>
        <div class="panel-body" v-if="topNewsItem">
          <Swiper
            ref="mySwiper"
            class="top-news-img"
            :options="swiperOptions"
            @slideChangeTransitionEnd="handleSlideChange"
          >
            <SwiperSlide v-for="item in topNews" :key="item.id">
              <img @click="$router.push('/article/13/' + topNewsItem.id)" :src="item.titlePic" style="height:350px;"/>
            </SwiperSlide>
            <div class="top-news-img-button top-news-img-button--prev"></div>
            <div class="top-news-img-button top-news-img-button--next"></div>
          </Swiper>
          <div class="top-news-info">
            <div class="news-list-item">
              <h4 class="news-list-item--title">
                <a @click="$router.push('/article/13/' + topNewsItem.id)">{{ topNewsItem.title }}</a>
                <span class="news-list-item--title-extra">
                  <em class="news-list-item--new" />
                  ({{ topNewsItem.gmtCreate | parseTimeNew('YYYY-MM-DD HH:mm') }})</span>
              </h4>
              <div class="news-list-item--content">{{ topNewsItem.titleDescription }}</div>
              <div class="panel-footer">
                <a @click="$router.push('/article/13/' + topNewsItem.id)">Xem chi tiết</a>
              </div>
            </div>
          </div>
          <div class="other-news">
            <a class="other-news-title">Xem tất cả</a>
            <div class="other-news-list news-list news-list__grid">
              <div v-for="item in otherNews" :key="item.id" class="news-list-item">
                <img v-if="item.titlePic" class="news-list-item--img" :src="item.titlePic" />
                <a @click="$router.push('/article/13/' + topNewsItem.id)" class="news-list-item--title">{{ item.title }}</a>
                <em class="news-list-item--new" />
                <div class="news-list-item--extra">
                  <span>({{ item.gmtCreate | parseTimeNew('YYYY-MM-DD HH:mm') }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1">
      <Menu />
      <WebLinks />
      <img src="/images/Native.png" style="width:100%;" />
      <div class="panel">
        <div class="panel-header">Video tiêu biểu</div>
        <div class="panel-body">
          <div class="news-list news-list__vertical">
            <div class="news-list-item">
              <video src="https://www.sbvgov.com/audio/audio.mp4" style="width:100%;height:150px" autoPlay loop muted playsInline controls></video>
              <a href="#" class="news-list-item--title">
                Xác thực sinh trắc học giúp bảo đảm an toàn giao dịch, ngăn chặn lừa đảo
              </a>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;text-align:right;">
        <a href="https://secure.livechatinc.com/licence/18506508/open_chat.cgi">
          <img src="/images/service.svg" style="width:50%;" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { Menu, Nav, WebLinks } from '@/components'
import {GET_articleListAsync} from "@/service";

export default {
  components: {
    Menu,
    Nav,
    WebLinks,
  },
  data() {
    return {
      swiperOptions: {
        allowTouchMove: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        speed: 500,
        autoplay: {
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.top-news-img-button--next',
          prevEl: '.top-news-img-button--prev',
        },
      },
      swiperActiveIndex: 0,
      topNews: [],
      otherNews: [],
      pressRelease: [
        {
          id: '1',
          title: 'Thông cáo báo chí về việc ban hành Thông tư số 22/2024/TT-NHNN sửa đổi, bổ sung một số điều của Thông tư số 50/2018/TT-NHNN',
          date: '07/05/2024',
          download: "1.pdf",
        },
        {
          id: '2',
          title: 'Thông cáo báo chí về việc ban hành Thông tư số 18/2024/TT-NHNN quy định về hoạt động thẻ ngân hàng',
          date: '07/02/2024',
          download: "2.doc",
        },
        {
          id: '3',
          title: 'Quyết định số 1125/QĐ-NHNN ngày 16/6 /2023 về mức lãi suất cho vay ngắn hạn tối đa bằng VND của TCTD đối với khách hàng vay để đáp ứng nhu cầu vốn phục vụ một số lĩnh vực, ngành kinh tế theo quy định tại Thông tư số 39/2016/TT-NHNN ngày 30/12/ 2016',
          date: '07/02/2024',
          download: "3.pdf"
        },
      ],
      pressRelease2: [
        {
          id: '1',
          title: 'Thông tư 12/2024/TT-NHNN ngày 28/06/2024 vv Sửa đổi, bổ sung một số điều của Thông tư số 39/2016/TT-NHNN ngày 30 tháng 12 năm 2016 của Thống đốc Ngân hàng Nhà nước Việt Nam quy định về hoạt động cho vay của tổ chức tín dụng, chi nhánh ngân hàng nước ngoài đối với khách hàng',
          date: '07/02/2024',
          download: "4.pdf"
        },
      ]
    }
  },
  created(){
    this.getData()
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    topNewsItem() {
      return this.topNews[this.swiperActiveIndex]
    },
  },
  methods: {
    getData(){
      GET_articleListAsync({ typeId: 13, page: 0, size: 5 }).then((response) => {
        this.topNews = response.data.Data.content
        this.topNewsItem = this.topNews[0]
      })
      GET_articleListAsync({ typeId: 13, page: 1, size: 6 }).then((response) => {
        this.otherNews = response.data.Data.content
      })
    },
    handleSlideChange() {
      this.swiperActiveIndex = this.swiper.activeIndex
    },
  },
}
</script>
<style lang="less">
@import url(./style.less);
</style>
